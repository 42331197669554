import { useState, useEffect, useCallback } from "react";
import Head from "next/head";
import { FormattedMessage, useIntl } from "react-intl";

function onDomReady(callbackFunc) {
  if (document.readyState !== "loading") {
    callbackFunc();
  } else if (document.addEventListener) {
    document.addEventListener("DOMContentLoaded", callbackFunc);
  } else {
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState === "complete") {
        callbackFunc(true);
      }
    });
  }
}

const HtmlHead = ({ pathname, asPath, metas, metaVal }) => {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />;
        <meta name="application-name" content="Spin And Win" />
        <title key="title">Spin And Win</title>
        <link
          rel="canonical"
          href={"https://spinandwin.co.tz" + (pathname || "")}
        />
        <meta
          name="description"
          content={<FormattedMessage id="best_online_casino_heading" />}
        />
      </Head>
    </>
  );
};

export default HtmlHead;
