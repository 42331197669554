import { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { useRouter } from "next/router";

import styles from "../assets/styles/reset.scss";

import English from "../../content/compiled-locales/en.json";
import German from "../../content/compiled-locales/de.json";
import HtmlHead from "../components/html/head.html";
import HeadScriptsHtml from "../components/html/headScripts.html";

function MyApp({ Component, pageProps }) {
  const { locale } = useRouter();
  const [shortLocale] = locale ? locale.split("-") : ["en"];

  const messages = useMemo(() => {
    switch (shortLocale) {
      case "en":
        return English;
      case "de":
        return German;
      default:
        return English;
    }
  }, [shortLocale]);

  return (
    <IntlProvider locale={shortLocale} messages={messages} onError={() => null}>
      <HtmlHead />
      <HeadScriptsHtml />
      <Component {...pageProps} />
      <style jsx>{styles}</style>
    </IntlProvider>
  );
}

export default MyApp;
